<script lang="ts" context="module">
  export type CountryItem = { iconId: string; value: string; label: string }
</script>

<script lang="ts">
  import { useId } from '$lib/hooks/use-id'
  import countryCodeEmoji from 'country-code-emoji'
  import { createEventDispatcher } from 'svelte'
  import Select from 'svelte-select/no-styles/Select.svelte'
  import OriginSelectorList from './OriginSelectorList.svelte'
  // import 'svelte-select/tailwind.css'
  import Item from './SelectItem.svelte'
  import SelectorIcon from './SelectorIcon.svelte'

  export let items: CountryItem[] = []
  export let value: CountryItem
  export let label = 'Traveling From'
  let className = ''
  export { className as class }

  const dispatch = createEventDispatcher<{ change: CountryItem }>()
  function handleSelect(event: CustomEvent<CountryItem>) {
    dispatch('change', event.detail)
  }
  const id = useId()

  let list: HTMLDivElement = null
</script>

<div class="flex flex-col items-start w-full sm:max-w-md overflow-clip" bind:this={list}>
  <label
    class="text-xs font-medium tracking-wide min-w-[12rem] text-gray-600 uppercase 2xl:text-sm leading-tight mt-[5px] mb-1 md:mb-2"
    for={'select-' + id}>{label}</label
  >
  <Select
    id={'select-' + id}
    class={className +
      ' svelte-select-origin w-full overflow-hidden rounded-md box-border h-9 flex items-center py-0 bg-white -m-0 relative'}
    {items}
    {value}
    on:select={handleSelect}
    isClearable={false}
    showChevron
    {Item}
    List={OriginSelectorList}
    ChevronIcon={SelectorIcon}
    listAutoWidth={true}
    isFocused={false}
    listOpen={false}
    appendListTarget={list}
    listPlacement="bottom"
    getSelectionLabel={(item) =>
      `<span class="mr-1 text-2xl md:mr-2">${countryCodeEmoji(
        item.iconId || 'US',
      )}</span> <span class="w-full max-w-sm overflow-hidden text-sm truncate">${
        item.label
      }</span>`}
  />
</div>

<style global lang="postcss">
  /* .svelte-select {
    position: relative !important;
  } */

  /* .svelte-select + .list {
    position: absolute !important;
    top: unset !important;
    margin-top: 0.5rem;
    margin-left: 1rem;
    left: 0 !important;
  }
   */

  .svelte-select-origin input {
    @apply absolute appearance-none bg-transparent cursor-pointer text-sm left-0 border-none ml-7 md:ml-8 pr-12 p-0 sm:text-sm outline-none focus:outline-none focus:ring-0;
  }

  /* .svelte-select.focused {
    @apply outline-none;
  } */

  /* .svelte-select.disabled {
    @apply bg-grey-dk border-grey-main text-gray-600;
  }

  .svelte-select.disabled input {
    @apply placeholder:text-grey-dk placeholder:opacity-100 px-0 mx-0;
  } */

  .svelte-select .selected-item {
    @apply overflow-x-hidden pr-5 pl-0 focus:outline-none truncate text-ellipsis w-full;
  }

  .selection {
    @apply flex items-center;
  }

  .svelte-select .icons {
    @apply absolute flex items-center right-0 text-gray-600 top-0 bottom-0;
  }

  .svelte-select .icons > * {
    @apply transition-colors ease-in-out duration-200;
  }

  .svelte-select .clear-select {
    @apply pointer-events-auto;
  }

  .svelte-select.focused .icons,
  .svelte-select .chevron:hover,
  .svelte-select .clear-select:hover {
    @apply text-black;
  }
  /*
  .svelte-select .clear-select {
    @apply h-5 text-grey-dk flex-none w-9;
  } */
  .svelte-select.show-chevron {
    @apply w-full;
  }

  .svelte-select .chevron {
    @apply pt-1 pl-3 md:pl-4 w-8 h-6 text-black cursor-pointer -mt-1;
  }

  .svelte-select.error {
    @apply border-red-500 bg-white;
  }

  .a11y-text {
    @apply sr-only;
  }

  .list {
    @apply mt-1 min-w-max z-20 w-full sm:max-w-[404px] max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm;
  }

  .list .list-group-title {
    @apply text-gray-600 cursor-default text-sm font-medium h-10 leading-10 px-5 overflow-ellipsis whitespace-nowrap uppercase;
  }

  .list .empty {
    @apply text-center py-5 text-gray-600;
  }

  .item {
    @apply cursor-pointer h-10 leading-10 hover:cursor-pointer px-5 text-gray-800 overflow-ellipsis overflow-hidden whitespace-nowrap;
  }

  .item.group-item {
    @apply px-10;
  }

  .item:active {
    @apply bg-grey-main;
  }

  .item.active {
    @apply bg-grey-main;
  }

  .item.not-selectable {
    @apply text-grey-dk;
  }

  .item.first {
    @apply rounded-t-sm;
  }

  .item.hover:not(.active) {
    @apply bg-grey-main;
  }
</style>
